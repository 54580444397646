import * as React from 'react';
import packageJson from '../../../package.json';
(global as any).appVersion = packageJson.version;

const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

type CacheBusterProps = {
  children: (props: any) => any;
}

type CacheBusterState = {
  loading: boolean;
  isLatestVersion: boolean;
  currentVersion: string;
  refreshCacheAndReload: () => any;
}

export default class CacheBuster extends React.Component<CacheBusterProps, CacheBusterState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      currentVersion: '',
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        if (caches) {
          caches.keys().then((names) => {
            const items = names.map(async (name: string) => {
              return Promise.resolve(caches.delete(name))
            });
            Promise.all(items).then(() => {
              window.location.reload(true);
            });
          })
        }
      }
    };
  }

  componentDidMount() {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = (global as any).appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          this.setState({ loading: false, isLatestVersion: false, currentVersion } as CacheBusterState);
        } else {
          this.setState({ loading: false, isLatestVersion: true, currentVersion } as CacheBusterState);
        }
      });
  }

  render() {
    const { loading,
      isLatestVersion,
      currentVersion,
      refreshCacheAndReload
    } = this.state;
    return this.props.children ? this.props.children(
      { loading, isLatestVersion, currentVersion, refreshCacheAndReload }
      ) : null;
  }
}
