import * as React from 'react';
import AvatarSQ from '../../assets/images/avatar-sq.jpg';
import BookIcon from '../../assets/icons/book-icon.png';
import FootballIcon from '../../assets/icons/football-icon.png';
import MovieIcon from '../../assets/icons/movie-icon.png';
import MusicIcon from '../../assets/icons/music-icon.png';
import PaintingIcon from '../../assets/icons/painting-icon.png';
import ProgramingIcon from '../../assets/icons/programing-icon.png';
import ExpBar from '../exp-bar/exp-bar';
import ProgressBar from '../progress-bar/progress-bar';

class Home extends React.Component {
  render() {
    return (
      <>
        <header className="header-wrapper">
          <div className="container">
            <div className="profile">
              <div className="avatar">
                <img src={AvatarSQ} alt="Chotikarn Rungviboon"/>
              </div>
              <div className="name">
                <h3>
                  Chotikarn <span className="primary-color">Rungviboon</span>
                </h3>
                <h5>
                  Full-Stack Developer
                </h5>
              </div>
            </div>
            <div className="personal">
              <div className="about">
                <h6 className="header">
                  About
                </h6>
                <p>
                  A self-taught programmer, who graduated from law school, but still have passion in computer and programing from a young age.
                </p>
              </div>
              <div className="biological">
                <h6 className="header">
                  Personal
                </h6>
                <div>
                  <ul>
                    <li>
                      Birthdate: 21 September 1986
                    </li>
                    <li>
                      National:   Thai
                    </li>
                    <li>
                      Religion:   Buddhist
                    </li>
                    <li>
                      Education: LL.B. (Ramkhamhaeng)
                    </li>
                  </ul>
                </div>
              </div>
              <div className="contact">
                <h6 className="header">
                  Contact
                </h6>
                <div>
                  <ul>
                    <li>
                      Mobile: 063-495-5445
                    </li>
                    <li>
                      E-mail:  chotikarn.r@live.com
                    </li>
                    <li>
                      Website: <a href="https://chotikarn.me" target="_blank" rel="noopener noreferrer">
                      chotikarn.me
                    </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="content-wrapper">
          <div className="container">
            <div className="skill-container">
              <div className="col-12 col-md-4">
                <h6>Programing Skills</h6>
                <ul className="skills">
                  <li className="skill-item">
                    <div className="skill-label">Front-end (10 Years)</div>
                    <div className="skill-desc">HTML3/CSS3/SASS</div>
                    <ExpBar points={9}/>
                  </li>
                  <li className="skill-item">
                    <div className="skill-label">PHP (8 Years)</div>
                    <div className="skill-desc">Composer/Symfony</div>
                    <ExpBar points={9}/>
                  </li>
                  <li className="skill-item">
                    <div className="skill-label">JavaScript (6 Years)</div>
                    <div className="skill-desc">NodeJS/TypeScript</div>
                    <ExpBar points={8}/>
                  </li>
                  <li className="skill-item">
                    <div className="skill-label">SQL/Database (8 Years)</div>
                    <div className="skill-desc">MySQL/MSSQL</div>
                    <ExpBar points={7}/>
                  </li>
                  <li className="skill-item">
                    <div className="skill-label">NoSQL (4 Years)</div>
                    <div className="skill-desc">MongoDB/Firebase</div>
                    <ExpBar points={7}/>
                  </li>
                  <li className="skill-item">
                    <div className="skill-label">Back-end (4 Years)</div>
                    <div className="skill-desc">Restful API/NodeJS</div>
                    <ExpBar points={7}/>
                  </li>
                </ul>
                <h6>Frameworks</h6>
                <ul className="skills">
                  <li className="skill-item">
                    <div className="skill-label">Laravel</div>
                    <ExpBar points={9}/>
                  </li>
                  <li className="skill-item">
                    <div className="skill-label">Angular 2+</div>
                    <ExpBar points={7}/>
                  </li>
                  <li className="skill-item">
                    <div className="skill-label">ReactJS</div>
                    <ExpBar points={8}/>
                  </li>
                  <li className="skill-item">
                    <div className="skill-label">ExpressJS</div>
                    <ExpBar points={8}/>
                  </li>
                  <li className="skill-item">
                    <div className="skill-label">Google Cloud/Firebase</div>
                    <ExpBar points={7}/>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-8">
                <h6>EXPERIENCE</h6>
                <ul className="experiences">
                  <li className="experience-item">
                    <div className="year">
                      <div className="label present">Present</div>
                    </div>
                    <div className="detail present">
                      <h4 className="label">Intocode Co.,Ltd. <span className="sub-label">
                        (2019-present)</span>
                      </h4>
                      <p className="label text-bold">Position: <span className="sub-label text-uppercase">
                        SR. Full-Stack Developer</span>
                      </p>
                      <p className="label text-bold">Technology/Stack: <span className="sub-label text-uppercase">
                        HTML5, CSS3, SASS, PHP, Laravel, NodeJS,
                        Angular2+, ReactJS, MySQL, NoSQL, ExpressJS, Firebase, Linux
                      </span>
                      </p>
                    </div>
                  </li>
                  <li className="experience-item">
                    <div className="year">
                      <div className="label">2017</div>
                    </div>
                    <div className="detail">
                      <h4 className="label">Delight Solution Co.,Ltd. <span className="sub-label">
                        (2017 - 2019)</span>
                      </h4>
                      <p className="label text-bold">Position: <span className="sub-label text-uppercase">
                        Full-Stack Developer</span>
                      </p>
                      <p className="label text-bold">Technology/Stack: <span className="sub-label text-uppercase">
                        HTML5, CSS3, SASS, PHP, Laravel, NodeJS,
                        Angular2+, ReactJS, MySQL, NoSQL, ExpressJS, Firebase, Linux
                      </span>
                      </p>
                    </div>
                  </li>
                  <li className="experience-item">
                    <div className="year">
                      <div className="label">2016</div>
                    </div>
                    <div className="detail">
                      <h4 className="label">Xeersoft (Thailand) Co.,Ltd. <span className="sub-label">(2016 - 2017)</span></h4>
                      <p className="label text-bold">Position: <span className="sub-label text-uppercase">
                        SOFTWARE DEVELOPER (ERP SOFTWARE)</span>
                      </p>
                      <p className="label text-bold">Technology/Stack: <span className="sub-label text-uppercase">
                        HTML5, CSS3, SASS, NodeJS, AngularJS, ExpressJS, MySQL, Linux
                      </span>
                      </p>
                    </div>
                  </li>
                  <li className="experience-item">
                    <div className="year">
                      <div className="label">2012</div>
                    </div>
                    <div className="detail">
                      <h4 className="label">Freelancer <span className="sub-label">(2012 - 2015)</span></h4>
                      <p className="label text-bold">Position: <span className="sub-label text-uppercase">
                        Website Developer/SEO</span>
                      </p>
                      <p className="label text-bold">Technology/Stack: <span className="sub-label text-uppercase">
                        HTML5, CSS3, SASS, Mircoformat, jQuery, PHP, Laravel, MySQL, Linux
                      </span>
                      </p>
                    </div>
                  </li>
                </ul>
                <h6>PERSONAL SKILLS</h6>
                <ul className="characteristics">
                  <li className="item">
                    <div className="label">Work Ethic</div>
                    <ProgressBar points={90}/>
                  </li>
                  <li className="item">
                    <div className="label">Problem-Solving</div>
                    <ProgressBar points={90}/>
                  </li>
                  <li className="item">
                    <div className="label">Time Management</div>
                    <ProgressBar points={80}/>
                  </li>
                  <li className="item">
                    <div className="label">Communication</div>
                    <ProgressBar points={70}/>
                  </li>
                  <li className="item">
                    <div className="label">Adaptability</div>
                    <ProgressBar points={70}/>
                  </li>
                  <li className="item">
                    <div className="label">Creativity</div>
                    <ProgressBar points={70}/>
                  </li>
                </ul>
                <h6>HOBBIES AND INTERESTS</h6>
                <ul className="interests">
                  <li className="item">
                    <div className="badge">
                      <img src={BookIcon} alt="Books"/>
                    </div>
                    <div className="label">Books</div>
                  </li>
                  <li className="item">
                    <div className="badge">
                      <img src={MusicIcon} alt="Football"/>
                    </div>
                    <div className="label">Music</div>
                  </li>
                  <li className="item">
                    <div className="badge">
                      <img src={ProgramingIcon} alt="Programing"/>
                    </div>
                    <div className="label">Programing</div>
                  </li>
                  <li className="item">
                    <div className="badge">
                      <img src={FootballIcon} alt="Football"/>
                    </div>
                    <div className="label">Football</div>
                  </li>
                  <li className="item">
                    <div className="badge">
                      <img src={PaintingIcon} alt="Painting"/>
                    </div>
                    <div className="label">Digital Painting</div>
                  </li>
                  <li className="item">
                    <div className="badge">
                      <img src={MovieIcon} alt="Movie/Netflix"/>
                    </div>
                    <div className="label">Movie/Netflix</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Home;
