import * as React from 'react';

class ExpBar extends React.Component<any, {points: number;}> {

  expPoints = (points: number) => {
    if (points > 10) {
      points = 10;
    }
    const remains = Array.from({length: 10 - points});
    const totals = Array.from({length: points});
    return (
      <>
        {
          totals.map(i => (<div className="exp-point filled"/>))
        }
        {
          remains.map(i => (<div className="exp-point"/>))
        }
      </>
    )
  };

  render() {
    const { points } = this.props;
    return (
      <div className="exp-bar">
        {
          this.expPoints(points)
        }
      </div>
    );
  }
}

export default ExpBar;
