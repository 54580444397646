import * as  React from 'react';
import CacheBuster from './components/cache-buster/cache-buster';
import Home from './components/pages/home';

export class App extends React.Component<any, any> {
  render(): React.ReactElement<any>  {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, currentVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          return (
            <div className="wrapper">
              <Home/>
              {
                !loading && !isLatestVersion &&
                <div className="alert alert-info update-alert">
                  <strong>
                    New content available!
                  </strong> Please <button type="button"
                                           className="btn btn-info"
                                           onClick={() => refreshCacheAndReload()}
                >
                  update
                </button> to see new content.
                </div>
              }
              <div className="current-version">
                {currentVersion}
              </div>
            </div>
          );
        }}
      </CacheBuster>
    )
  }
}

export default App;
