import * as React from 'react';

class ProgressBar extends React.Component<any, {points: number;}> {

  expPoints = (points: number) => {
    if (points > 100) {
      points = 100;
    }
    return (
      <>
        <div className="progression" style={{width: points + '%'}}/>
        <div className="total"/>
      </>
    )
  };

  render() {
    const { points } = this.props;
    return (
      <div className="progress-bar">
        {
          this.expPoints(points)
        }
      </div>
    );
  }
}

export default ProgressBar;
